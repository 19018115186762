import React from "react";
import moment from "moment";
import "moment-timezone";

class Conversations extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: true,
		};
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.conversations !== this.props.conversations) {
			this.setState({ loading: false });
		}
	}

	renderConversations(conversations) {
		const currentDate = moment().startOf("day");
		const sortedConversations = [...conversations].sort((a, b) =>
			moment(b.last_updated).diff(moment(a.last_updated))
		);

		const groupedConversations = sortedConversations.reduce(
			(groups, conversation) => {
				const lastUpdatedDate = moment(conversation.last_updated, "YYYY-MM-DD");
				let label;

				if (lastUpdatedDate.isSame(currentDate, "day")) {
					label = "Today";
				} else if (
					lastUpdatedDate.isSame(currentDate.clone().subtract(1, "days"), "day")
				) {
					label = "Yesterday";
				} else if (
					lastUpdatedDate.isAfter(currentDate.clone().subtract(7, "days"))
				) {
					label = "Last 7 days";
				} else if (
					lastUpdatedDate.isAfter(currentDate.clone().subtract(30, "days"))
				) {
					label = "Last 30 days";
				} else if (lastUpdatedDate.isSame(currentDate, "year")) {
					label = lastUpdatedDate.format("MMMM"); // 'MMMM' format will output the month name
				} else {
					label = lastUpdatedDate.format("YYYY"); // 'YYYY' will output in 'year' format
				}

				if (!groups[label]) groups[label] = [];
				groups[label].push(conversation);
				return groups;
			},
			{}
		);

		return Object.entries(groupedConversations).map(
			([group, conversations]) => (
				<div key={group}>
					<h4 className="date-grouper">{group}</h4>
					<ul className="conversation-list">
						{conversations.map((conversation) => (
							<li
								key={conversation.chatId}
								onClick={() =>
									this.props.handleReconnectChat(conversation.chatId)
								}
							>
								<div className="conversation-item">
									<svg
										className="svg-icon"
										stroke="currentColor"
										fill="none"
										strokeWidth="2"
										viewBox="0 0 24 24"
										strokeLinecap="round"
										strokeLinejoin="round"
										height="1em"
										width="1em"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path>
									</svg>
									<p>{conversation.subject || conversation.chatId}</p>
								</div>
							</li>
						))}
					</ul>
				</div>
			)
		);
	}

	render() {
		const { conversations } = this.props;
		const { loading } = this.state;

		// console.log(this.props);
		return (
			<div className="menu-content">
				{loading ? (
					<div>
						<ul className="conversation-list">
							<li>
								{/* <div className="conversation-item">Loading...</div> */}
								<div className="spinner">
									<div></div>
								</div>
							</li>
						</ul>
					</div>
				) : (
					conversations && this.renderConversations(conversations)
				)}{" "}
			</div>
		);
	}
}

export default Conversations;
