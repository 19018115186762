import React, { useEffect, useRef } from 'react';

import SyntaxHighlighter from 'react-syntax-highlighter';

import { darcula, docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import Alert from './Alert';

const MessageList = ({ messages, theme, handleCopyClick, copiedIndex, showCopySuccess, handleToggleMenu, status }) => {
  const messagesEndRef = useRef(null);
  
  useEffect(() => {
    if (status !== 'loading' && messagesEndRef.current) {
      // Scroll into view when the status is not 'loading'
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [status, messages]);

  useEffect(() => {
    if (status === 'loading' && messagesEndRef.current) {
      setTimeout(() => {
        messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
      }, 100); // Add a slight delay to ensure the element is rendered
    }
  }, [status]);
  

  function animateString(str, delayBase) {
    let array = str.split("").map((char, i) => (
      <span
        style={{ animationDelay: `${delayBase * i}s` }}
        className="animatedChar"
      >
        {char}
      </span>
    ));
    array.push(
      <img
        style={{ animationDelay: `${delayBase * str.length + 0.5}s` }}
        className="animatedCharImg"
        src="/logo192.png"
      />
    );
    return array;
  }
  
  return (
    <div className="flex h-full	 w-full flex-col">
      <div className="flex-1 overflow-y-auto rounded-xl bg-slate-200 p-4 text-sm leading-6 text-slate-900 dark:bg-slate-800 dark:text-slate-300 sm:text-base sm:leading-7">
                         {/* Render messages or empty state */}
      {messages.length > 0 ? (
        messages.map((message, index) => {
          let messageClass = message.sender === "You" 
            ? "mb-4 flex rounded-xl bg-slate-200 px-2 py-6 dark:bg-slate-800 sm:px-4"
            : "mb-4 flex rounded-xl bg-slate-50 px-2 py-6 dark:bg-slate-900 sm:px-4";

          // For sponsored messages, add additional handling if needed

          return (
            <div key={index} className={messageClass}>
              <img
                className="mr-2 flex h-8 w-8 rounded-full sm:mr-4"
                src={message.sender === "You" ? "https://dummyimage.com/256x256/363536/ffffff&text=You" : "https://dummyimage.com/256x256/354ea1/ffffff&text=K"}
              />
              <div className=" max-w-3xl items-center rounded-xl whitespace-break-spaces">
                {message.text.split(/(```[^\n]*?[\s\S]*?```)/).map((part, i) =>
                  part.match("```[^`]*```") ? (
                    <div key={i} style={{ position: "relative" }}>
                      <SyntaxHighlighter
                        language={part.match(/```(\w+)/)?.[1] ? part.match(/```(\w+)/)[1] : "plaintext"}
                        style={theme === "dark" ? darcula : docco}
                      >
                        {part.replace(/```[^\n ]*?\n?/, "").replace("```", "").trim()}
                      </SyntaxHighlighter>
                      <button
                        onClick={() => handleCopyClick(part.replace(/```[^\n ]*?\n?/, "").replace("```", "").trim(), i)}
                        style={{ position: "absolute", top: 0, right: 0, fontWeight: "bold" }}
                      >
                        {copiedIndex === i ? "Copied!" : "Copy"}
                      </button>
                    </div>
                    
                  ) : (
                    <span key={i}>
                      {part.split(/(https?:\/\/[^\s]+|www\.[^\s]+)/).map((segment, j) =>
                        /(https?:\/\/[^\s]+|www\.[^\s]+)/.test(segment) ? (
                          <a key={j} href={segment.startsWith('www') ? 'http://' + segment : segment} target="_blank" rel="noopener noreferrer">{segment}</a>
                        ) : (
                          segment
                        )
                      )}
                    </span>
                  )
                )}
                {message.sender === "You" ? " "
:              <div className="mt-4 flex flex-row gap-x-2 text-slate-500 lg:mt-0">
  {/*}
        <button className="hover:text-blue-600" >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            viewBox="0 0 24 24"
            strokeWidth="2"
            stroke="currentColor"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path
              d="M7 11v8a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1v-7a1 1 0 0 1 1 -1h3a4 4 0 0 0 4 -4v-1a2 2 0 0 1 4 0v5h3a2 2 0 0 1 2 2l-1 5a2 3 0 0 1 -2 2h-7a3 3 0 0 1 -3 -3"
            ></path>
          </svg>
        </button>
        <button className="hover:text-blue-600" type="button">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            viewBox="0 0 24 24"
            strokeWidth="2"
            stroke="currentColor"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path
              d="M7 13v-8a1 1 0 0 0 -1 -1h-2a1 1 0 0 0 -1 1v7a1 1 0 0 0 1 1h3a4 4 0 0 1 4 4v1a2 2 0 0 0 4 0v-5h3a2 2 0 0 0 2 -2l-1 -5a2 3 0 0 0 -2 -2h-7a3 3 0 0 0 -3 3"
            ></path>
          </svg>
        </button>
        */}
        <button className="hover:text-blue-600" type="button"
        onClick={() => handleCopyClick(message.text, index)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            viewBox="0 0 24 24"
            strokeWidth="2"
            stroke="currentColor"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path
              d="M8 8m0 2a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v8a2 2 0 0 1 -2 2h-8a2 2 0 0 1 -2 -2z"
            ></path>
            <path
              d="M16 8v-2a2 2 0 0 0 -2 -2h-8a2 2 0 0 0 -2 2v8a2 2 0 0 0 2 2h2"
            ></path>
          </svg>
        </button>
      </div>}
              </div>
              
            </div>  
          );
        })
        ) : (
          <div className="blank-convo-history">
          <h2>{animateString("YidGPT", 0.1)}</h2>
        </div>
        )}
  
          {status === 'loading' && (
          <div ref={messagesEndRef}  className="mt-4 flex justify-center" aria-hidden="true">
            <div className="flex animate-pulse space-x-2">
              <div className="h-2 w-2 rounded-full bg-slate-600"></div>
              <div className="h-2 w-2 rounded-full bg-slate-600"></div>
              <div className="h-2 w-2 rounded-full bg-slate-600"></div>
            </div>
          </div>
        )}
         {showCopySuccess && (
            <Alert type="success" title="Copy" message="Text copied successfully!" />
            )}
                  <div  />
                  <div ref={messagesEndRef} />

      </div>

    </div>
  );
};

export default MessageList;
