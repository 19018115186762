import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { ThemeContext } from "./ThemeContext";
import { Amplify, Auth } from "aws-amplify";
// import "./App.css";
import Conversations from "./components/Conversations";
import { withAuthenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
// import Moment from "react-moment";
import { Light as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula, docco } from "react-syntax-highlighter/dist/esm/styles/hljs";

import awsExports from "./aws-exports";
Amplify.configure(awsExports);

export function ThemeProvider({ children }) {
	const [theme, setTheme] = useState("light"); // default value is 'light'

	const toggleTheme = () => {
		const newTheme = theme === "light" ? "dark" : "light";
		// console.log("New theme:", newTheme); // add this line
		setTheme(newTheme);
	};

	React.useEffect(() => {
		// console.log("Theme changed:", theme);
	}, [theme]);

	return (
		<ThemeContext.Provider value={{ theme, toggleTheme }}>
			{children}
		</ThemeContext.Provider>
	);
}
function AppWrapper() {
	const navigation = useNavigate();
	return <App navigate={navigation} />;
}
// function animateString(str, delayBase) {
// 	return str.split("").map((char, i) => (
// 		<span
// 			style={{ animationDelay: `${delayBase * i}s` }}
// 			className="animatedChar"
// 		>
// 			{char}
// 		</span>
// 	));
// }
function animateString(str, delayBase) {
	let array = str.split("").map((char, i) => (
		<span
			style={{ animationDelay: `${delayBase * i}s` }}
			className="animatedChar"
		>
			{char}
		</span>
	));
	array.push(
		<img
			style={{ animationDelay: `${delayBase * str.length + 0.5}s` }}
			className="animatedCharImg"
			src="/logo192.png"
		/>
	);
	return array;
}

class App extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			signOutLoading: false,
			chatId: null,
			messages: [],
			status: "idle",
			error: null,
			user: null,
			botTyping: false,
			showMenu: false,
			inputValue: "",
			copiedIndex: null,
			messageCount: 0,
		};
		this.hamburgerMenu = React.createRef();
	}

	componentDidMount() {
		this.initApp();
	}

	async initApp() {
		try {
			const user = await Auth.currentAuthenticatedUser();
			const chatId = "chat-" + Math.random().toString(36).substring(7);
			this.setState({ user, chatId });

			// Fetch the conversation history here
			this.fetchConversationHistory(user);
		} catch (err) {
			// console.log(err);
		}
	}

	componentDidUpdate() {
		// Scroll chat history to the bottom whenever state updates
		const chatHistory = this.chatHistory;
		if (chatHistory) {
			setTimeout(() => {
				chatHistory.scrollTop = chatHistory.scrollHeight;
			}, 0); // delay of 0ms
		}
	}
	handleInputChange = (event) => {
		this.setState({ inputValue: event.target.value });
	};

	handleCloseMenu = () => {
		this.setState({ showMenu: false });
	};

	handleToggleMenu = async () => {
		this.setState({ showMenu: !this.state.showMenu });
	};
	fetchConversationHistory = async (user) => {
		try {
			const response = await fetch(
				"https://wctmqv4tuz33l2f6ync465xziu0xfyfx.lambda-url.us-east-1.on.aws/",
				{
					method: "POST",
					headers: { "Content-Type": "application/json" },
					body: JSON.stringify({
						email: user.attributes.email,
						action: "fetchConversations",
						site: "YidGPT",
					}),
				}
			);

			if (response.ok) {
				const conversationHistory = await response.json();
				this.setState({ conversations: conversationHistory.conversations });
			} else {
				throw new Error("Server error");
			}
		} catch (error) {
			console.error("Error fetching conversation history:", error.message);
		}
	};
	handleReconnectChat = (chatId) => {
		this.setState({ chatId }, async () => {
			// Fetch messages for the selected chat here
			try {
				const response = await fetch(
					"https://wctmqv4tuz33l2f6ync465xziu0xfyfx.lambda-url.us-east-1.on.aws/",
					{
						method: "POST",
						headers: { "Content-Type": "application/json" },
						body: JSON.stringify({
							email: this.state.user.attributes.email,
							chatId: chatId,
							action: "fetchMessages",
							site: "YidGPT",
						}),
					}
				);

				if (response.ok) {
					const fetchedMessages = await response.json();
					this.setState({
						messages: fetchedMessages.messages,
						showMenu: false,
					});
				} else {
					throw new Error("Server error");
				}
			} catch (error) {
				console.error("Error fetching messages for chat:", error.message);
			}
		});
	};
	
	async generateAd() {
	    const { chatId, user, messageCount, messages } = this.state;
	    this.setState({ status: "loading" });

	    const type = "ad";
	        
	    const interactions = messages.slice(-2*messageCount).map(message => ({
	        role: message.sender === "You" ? "user" : "assistant",
	        content: message.text
	    }));
	
	    try {
	        const response = await fetch(
	            "https://wctmqv4tuz33l2f6ync465xziu0xfyfx.lambda-url.us-east-1.on.aws/", {
	            method: "POST",
	            headers: {
	                "Content-Type": "application/json"
	            },
	            body: JSON.stringify({
	                chatId,
	                From: this.state.user.attributes.email,
	                source: "webapp",
	                interactions,
	                type,
	                site: "YidGPT",
	            }),
	        }
	        );
	        
	        // If the response was not okay, throw an error
	        if (!response.ok) {
	            throw new Error(`HTTP error! status: ${response.status}`);
	        }
	        
	        const ad = await response.json(); // assuming the ad is returned in response
	        
	        // Now push the ad to your chat, if that's what you meant
	        this.setState({
		    messages: [...this.state.messages, { text: ad.message, sender: "YidGPT", type: ad.type }],
	            status: "success", // Successful response
	            botTyping: false,  // Bot has finished typing
	            messageCount: 0,   // resetting messageCount to 0
	        });
	    } catch (error) {
	        // Handle any errors here
	        console.error("Error occurred: ", error);
	    }
	}
	
	handleInput = (event, fromButton) => {
	    // Check if the event is from a button click or Enter key
	    if (fromButton || event.key === "Enter") {
	        event.preventDefault();
	        const userInput = fromButton
	            ? event.target.previousSibling.value
	            : event.target.value;
	        this.setState({ inputValue: "" });
	
	        // Add user message to chat
	        this.setState({
	            messages: [...this.state.messages, { text: userInput, sender: "You" }],
	            messageCount: this.state.messageCount + 1,  // don't forget to increment the count
	        });
	
	        this.setState({ status: "loading", error: null, botTyping: true }); // Start loading
	        fetch(
	            "https://wctmqv4tuz33l2f6ync465xziu0xfyfx.lambda-url.us-east-1.on.aws/",
	            {
	                method: "POST",
	                headers: { "Content-Type": "application/json" },
	                body: JSON.stringify({
	                    chatId: this.state.chatId,
	                    message: userInput,
	                    From: this.state.user.attributes.email,
	                    source: "webapp",
	                    site: "YidGPT",
	                }),
	            }
	        )
	            .then((response) => {
	                if (response.ok) {
	                    return response.json();
	                } else {
	                    throw new Error("Server error");
	                }
	            })
	            .then((data) => {
	                // Add bot's response to chat
	                this.setState({
	                    messages: [
	                        ...this.state.messages,
	                        { text: data.message, sender: "YidGPT" },
	                    ],
	                    status: "success", // Successful response
	                    botTyping: false,  // Bot has finished typing
	                }, () => { // important: use callback function to ensure state is updated
	                    // Now check if it's time to generate ad:
	                    if(this.state.messageCount >= 5) {
	                        this.generateAd();
	                    }
	                });
	            })
	            .catch((error) => {
	                this.setState({
	                    status: "error",
	                    error: error.message,
	                    botTyping: false,
	                });
	            });
	    }
	};

	handleNewConversation = () => {
		const chatId = "chat-" + Math.random().toString(36).substring(7);
		this.setState(
			{ chatId, messages: [], status: "idle", error: null },
			async () => {
				try {
					await this.fetchConversationHistory(this.state.user);
				} catch (error) {
					console.error(
						"Error refetching conversation history:",
						error.message
					);
				}
			}
		);
	};

	handleSignOut = async () => {
		try {
			this.setState({ signOutLoading: true }); // Start loading
			// console.log("started loading", this.state.signOutLoading);

			setTimeout(async () => {
				try {
					await Auth.signOut();
					// console.log("stopped loading", this.state.signOutLoading);
					this.setState({ signOutLoading: false }); // Stop loading
					this.props.navigate("/");
					// console.log("Signing out...");
				} catch (error) {
					// console.log("Error signing out:", error.message);
				}
			}, 1000); // adjust the delay time according to your needs
		} catch (error) {
			console.log(error);
		}
	};

	handleKeyPress = (event) => {
		if (event.key === "Enter") {
			if (this.state.status === "loading" || !this.state.inputValue.trim()) {
				event.preventDefault();
			} else {
				this.handleInput(event, false);
			}
		}
	};

	// Add a function to handle copying text to the clipboard
	handleCopyClick = (text, index) => {
		navigator.clipboard.writeText(text).then(
			() => {
				this.setState({ copiedIndex: index });
				setTimeout(() => {
					this.setState({ copiedIndex: null });
				}, 5000);
			},
			(err) => {
				console.error("Copy failed: ", err);
			}
		);
	};

	render() {
		if (this.state.signOutLoading) {
			return (
				<div className="redirect-spinner-cont">
					<div className="redirect-spinner">
						<div></div>
						<div></div>
						<div></div>
						<div></div>
						<div></div>
					</div>
				</div>
			);
		}
		return (
			<ThemeContext.Consumer>
				{({ theme, toggleTheme }) => {
					return (
						<>
							<div className={this.state.showMenu ? "overlay" : ""}></div>

							<div
								className={
									theme === "dark" ? "outer-cont dark " : "outer-cont light "
								}
							>
								<header
									className="mobile"
									style={{
										display: "flex",
										justifyContent: "flex-end",
										alignItems: "center",
									}}
								>
									<button
										onClick={this.handleToggleMenu}
										className="header-icons mobile toggleMenu"
										style={{
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
											fontSize: "20px",
											lineHeight: "20px",
											// background: theme === "dark" ? "#333" : "#f0f0f0",
											// color: theme === "dark" ? "#f0f0f0" : "#333",
											// marginLeft: "5px",
											zIndex: "1",
											position: "relative",
										}}
									>
										<svg
											className="top-icon-svg"
											stroke="currentColor"
											fill="none"
											strokeWidth="1.5"
											viewBox="0 0 24 24"
											strokeLinecap="round"
											strokeLinejoin="round"
											xmlns="http://www.w3.org/2000/svg"
										>
											<line x1="3" y1="12" x2="21" y2="12"></line>
											<line x1="3" y1="6" x2="21" y2="6"></line>
											<line x1="3" y1="18" x2="21" y2="18"></line>
										</svg>
									</button>
									{/* <h1>
										{this.state.user &&
											`Hi, ${this.state.user.attributes.email}`}
									</h1> */}
									<button
										type="button"
										className="header-icons mobile"
										style={{ paddingRight: ".75rem" }}
										onClick={() => {
											this.handleNewConversation();
											this.handleCloseMenu();
										}}
										disabled={this.state.status === "loading"}
									>
										<svg
											className="top-icon-svg"
											stroke="currentColor"
											fill="none"
											strokeWidth="1.5"
											viewBox="0 0 24 24"
											strokeLinecap="round"
											strokeLinejoin="round"
											height="1.6rem"
											width="1.6rem"
											xmlns="http://www.w3.org/2000/svg"
										>
											<line x1="12" y1="5" x2="12" y2="19"></line>
											<line x1="5" y1="12" x2="19" y2="12"></line>
										</svg>
									</button>
									{/* <div style={{ width: "30px" }}></div>{" "} */}
								</header>
								<div className="convo-cont">
									<div className="convo-box-wrapper">
										<div
											ref={this.hamburgerMenu}
											className={`hamburger-menu ${
												this.state.showMenu
													? theme === "dark"
														? "dark-visible"
														: "light-visible"
													: theme === "dark"
													? "dark-hidden"
													: "light-hidden"
											}`}
										>
											<button
												type="button"
												className="close-icon"
												tabIndex="0"
												onClick={() => {
													this.handleCloseMenu();
												}}
												style={{
													display: this.state.showMenu ? "block" : "none",
												}}
											>
												<span className="sr-only">Close sidebar</span>
												<svg
													className="top-icon-svg"
													stroke="currentColor"
													fill="none"
													strokeWidth="1.5"
													viewBox="0 0 24 24"
													strokeLinecap="round"
													strokeLinejoin="round"
													xmlns="http://www.w3.org/2000/svg"
												>
													<line x1="18" y1="6" x2="6" y2="18"></line>
													<line x1="6" y1="6" x2="18" y2="18"></line>
												</svg>
											</button>
											<div className="">
												{/* <h3>Conversation History</h3> */}
												<button
													onClick={() => {
														this.handleNewConversation();
														this.handleCloseMenu();
													}}
													disabled={this.state.status === "loading"}
													className="button-new-chat"
												>
													<svg
														className="top-icon-svg"
														stroke="currentColor"
														fill="none"
														strokeWidth="1.5"
														viewBox="0 0 24 24"
														strokeLinecap="round"
														strokeLinejoin="round"
														height="1.6rem"
														width="1.6rem"
														xmlns="http://www.w3.org/2000/svg"
													>
														<line x1="12" y1="5" x2="12" y2="19"></line>
														<line x1="5" y1="12" x2="19" y2="12"></line>
													</svg>
													<span>New Chat</span>
												</button>
											</div>
										</div>

										<div id="convo-box">
											{this.state.messages.length === 0 && (
												<header
													className="desktop"
													style={{
														display: "flex",
														justifyContent: "space-between",
														alignItems: "center",
													}}
												>
													{/* <h1>
														{this.state.user &&
															`Hi, ${this.state.user.attributes.email}`}
													</h1> */}
													<div style={{ width: "30px" }}></div>{" "}
												</header>
											)}
											<div
												id="convo-history"
												ref={(el) => {
													this.chatHistory = el;
												}}
												style={
													this.state.messages.length === 0
														? { margin: "auto", opacity: 0.5 }
														: {}
												}
											>
												{this.state.messages.length === 0 ? (
													<div className="blank-convo-history">
														<h2>{animateString("YidGPT", 0.1)}</h2>
													</div>
												) : (
													<React.Fragment>
														<header
															className="desktop"
															style={{
																display: "flex",
																justifyContent: "space-between",
																alignItems: "center",
															}}
														>
															{/* <h1>
																{this.state.user &&
																	`Hi, ${this.state.user.attributes.email}`}
															</h1> */}
															<div style={{ width: "30px" }}></div>{" "}
														</header>
														{this.state.messages.map((message, index) => (
															<div
															      	className={
																    message.sender === "You"
																    ? "m-cont-user-question"
																    : message.type === "sponsored"
																    ? "m-cont-gpt-sponsored-response"
																    : "m-cont-gpt-response"
																}
																key={index}
															>
																<div
																	className={
																		message.sender === "You"
																			? "user-question"
																			: "gpt-response"
																	}
																>
																	<b className="m-author">{message.sender}:</b>{" "}
																	{/*{message.text} */}
																	{message.text
																		.split(/(```[^\n]*?[\s\S]*?```)/)
																		.map((part, i) =>
																			part.match("```[^`]*```") ? (
																				<div
																					key={i}
																					style={{ position: "relative" }}
																				>
																					<SyntaxHighlighter
																						language={
																							part.match(/```(\w+)/)?.[1]
																								? part.match(/```(\w+)/)[1]
																								: "plaintext"
																						}
																						style={
																							theme === "dark" ? darcula : docco
																						}
																					>
																						{part
																							.replace(/```[^\n ]*?\n?/, "")
																							.replace("```", "")
																							.trim()}
																					</SyntaxHighlighter>
																					<button
																						onClick={() =>
																							this.handleCopyClick(
																								part
																									.replace(/```[^\n ]*?\n?/, "")
																									.replace("```", "")
																									.trim(),
																								i
																							)
																						}
																						style={{
																							position: "absolute",
																							top: 0,
																							right: 0,
																							fontWeight: "bold",
																						}}
																					>
																						{this.state.copiedIndex === i
																							? "Copied!"
																							: "Copy"}
																					</button>
																				</div>
																			) : (
																				<span key={i}>
																				    {part.split(/(https?:\/\/[^\s]+|www\.[^\s]+)/).map((segment, j) =>
																				        /(https?:\/\/[^\s]+|www\.[^\s]+)/.test(segment) ? (
																				            <a key={j} href={segment.startsWith('www') ? 'http://' + segment : segment} target="_blank" rel="noopener noreferrer">{segment}</a>
																				        ) : (
																				            segment
																				        )
																				    )}
																				</span>
																			)
																		)}
																</div>
															</div>
														))}
														{this.state.status === "error" && (
															<div className="m-cont-gpt-response error">
																Error: {this.state.error}. Please try again.
															</div>
														)}
													</React.Fragment>
												)}

												{this.state.status === "loading" && (
													<div className="m-cont-gpt-response">
														{/* <p
															style={{
																fontStyle: "italic",
																color: "#41337a",
																opacity: "0.5",
															}}
															className="gpt-response"
														>
															YidGPT is typing...
														</p> */}
														<div
															className="gpt-response"
															style={{
																display: "flex",
																alignItems: "flex-end",
																color: "#41337a",
																opacity: "0.5",
															}}
														>
															<p>YidGPT is typing</p>
															<div className="typingDots">
																<div className="typingDot"></div>
																<div className="typingDot"></div>
																<div className="typingDot"></div>
															</div>
														</div>
													</div>
												)}
											</div>
											<div className="bottom-section">
												<div className="cont m-input-section">
													<textarea
														value={this.state.inputValue}
														onChange={this.handleInputChange}
														onKeyDown={this.handleKeyPress}
														tabIndex="0"
														data-id=""
														rows="1"
														placeholder="Chat..."
														className="textsection"
													/>
													<button
														onClick={(e) => this.handleInput(e, true)}
														disabled={
															this.state.status === "loading" ||
															!this.state.inputValue.trim()
														}
														className="button"
													>
														<svg
															xmlns="http://www.w3.org/2000/svg"
															viewBox="0 0 16 16"
															fill="none"
															strokeWidth="2"
														>
															<path
																d="M.5 1.163A1 1 0 0 1 1.97.28l12.868 6.837a1 1 0 0 1 0 1.766L1.969 15.72A1 1 0 0 1 .5 14.836V10.33a1 1 0 0 1 .816-.983L8.5 8 1.316 6.653A1 1 0 0 1 .5 5.67V1.163Z"
																fill="currentColor"
															></path>
														</svg>
													</button>
												</div>
												<div className="footer">
													<p>
														YidGPT is not reliable for Halacha or Zmanim.
														<button
															onClick={() => {
																this.handleSignOut();
																this.handleCloseMenu();
															}}
															className="button signout-button"
														>
															Sign Out
														</button>
													</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</>
					);
				}}
			</ThemeContext.Consumer>
		);
	}
}

export default withAuthenticator(AppWrapper);
