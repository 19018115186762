import React from 'react';
import moment from 'moment';

const Sidebar = ({ showMenu, username, conversationHistory, handleReconnectChat, handleNewConversation, handleCloseMenu, handleSignOut, handleToggleMenu, status, toggleTheme, theme }) => {

  // Function to format date similar to the old component
  const formatDate = (dateString) => {
    const currentDate = moment().startOf('day');
    const conversationDate = moment(dateString);
  
    // Check for Today and Yesterday
    if (conversationDate.isSame(currentDate, 'day')) {
      return 'Today';
    } else if (conversationDate.isSame(currentDate.clone().subtract(1, 'days'), 'day')) {
      return 'Yesterday';
    } else {
      // If not Today or Yesterday, return in "Jan 6" format
      return conversationDate.format('MMM D'); // "MMM" for abbreviated month, "D" for day of the month
    }
  };

// Function to sort the conversation history by last_updated, descending
const sortConversationHistory = (conversations) => {
    return conversations.sort((a, b) => moment(b.last_updated) - moment(a.last_updated));
  }
  
  // Updated function to render history conversations with sorting
  const renderHistoryConversations = () => {
    // Check if conversationHistory is available and an array
    if (!Array.isArray(conversationHistory)) {
      return <div>No Conversations Yet</div>;
    }
  
    // Sort the conversations by last updated time before mapping
    const sortedConversations = sortConversationHistory(conversationHistory);

    return conversationHistory.map((conversation, index) => (
      <button
        key={index}
        className="flex w-full flex-col gap-y-2 rounded-lg px-3 py-2 text-left transition-colors duration-200 hover:bg-slate-200 focus:outline-none dark:hover:bg-slate-800"
        onClick={() => handleReconnectChat(conversation.chatId)} // Added reconnect chat functionality
      >
        <h1 className="text-sm font-medium capitalize text-slate-700 dark:text-slate-200">
          {conversation.subject  || conversation.chatId}
        </h1>
        <p className="text-xs text-slate-500 dark:text-slate-400">
          {formatDate(conversation.last_updated)} {/* Updated to use formatted date */}
        </p>
      </button>
    ));
  };

  return (
    <>


      <aside className={`fixed z-50 md:relative md:flex`}>
      <button
            className={`${showMenu ? '-top-3 -right-12' : ''} z-90 block md:hidden p-3 animatedChar absolute`} 
            onClick={handleToggleMenu}
        >
             {/* Conditional rendering based on `showMenu` state */}
  {showMenu ? (
    // "X" Icon (Close)
    <svg  viewBox="0 0 24 24" width="30" height="30" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
      <line x1="18" y1="6" x2="6" y2="18"></line>
      <line x1="6" y1="6" x2="18" y2="18"></line>
    </svg>
  ) : (
    // Hamburger Icon (Menu)
    <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
      <line x1="3" y1="12" x2="21" y2="12"></line>
      <line x1="3" y1="6" x2="21" y2="6"></line>
      <line x1="3" y1="18" x2="21" y2="18"></line>
    </svg>
  )}

        </button>

      <div className={`flex ${showMenu ? 'flex md:hidden' : 'hidden md:flex'} h-[100svh] w-60 flex-col overflow-y-auto bg-slate-50 dark:border-slate-700 dark:bg-slate-900 sm:h-[100vh] sm:w-64`}>
        {/* Logo */}
        {/*
        <div className="flex px-4">
        
       
                            <img  className="h-7 w-7 text-blue-600" src="/logo192.png" />

    
        </div>
  */}
        <div className="mx-2 mt-8">
        <button
            className="flex w-full gap-x-4 rounded-lg border border-slate-300 p-4 text-left text-sm font-medium text-slate-700 transition-colors duration-200 hover:bg-slate-200 focus:outline-none dark:border-slate-700 dark:text-slate-200 dark:hover:bg-slate-800"
            onClick={() => {
                handleNewConversation();
                handleCloseMenu();
            }}
            disabled={status === "loading"}
        >
            <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            viewBox="0 0 24 24"
            strokeWidth="2"
            stroke="currentColor"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            >
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path d="M12 5l0 14"></path>
            <path d="M5 12l14 0"></path>
            </svg>
            New Chat
        </button>
        </div>

        {/* Conditional Rendering based on Loading State */}

          <div className="h-3/4 space-y-4 overflow-y-auto border-b border-slate-300 px-2 py-4 dark:border-slate-700">
            {renderHistoryConversations()}
          </div>


        {/* User Section */}
        <div className="flex flex-col	 items-center	 mt-auto w-full space-y-4 px-2 py-4">
          {/* ... User button ... */}
          <button className="flex w-full gap-x-2 rounded-lg px-3 py-2 text-left text-sm font-medium text-slate-700 transition-colors duration-200 hover:bg-slate-200 focus:outline-none dark:text-slate-200 dark:hover:bg-slate-800">
            <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            viewBox="0 0 24 24"
            strokeWidth="2"
            stroke="currentColor"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            >
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0"></path>
            <path d="M12 10m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0"></path>
            <path
                d="M6.168 18.849a4 4 0 0 1 3.832 -2.849h4a4 4 0 0 1 3.834 2.855"
            ></path>
            </svg>
            <span>{username}</span>
          </button>
          {/* Theme toggle buttons */}

          <div className="flex gap-2	 px-2 py-4 ml-3 text-sm font-medium text-slate-800 dark:text-slate-200">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" class="inline-flex h-4 w-4" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z"></path></svg>
            <label className="relative flex cursor-pointer items-center">
              <input type="checkbox" value="" className="peer sr-only" checked={theme === 'dark'}  onClick={toggleTheme} />
              <div
                className="peer h-6 w-11 rounded-full bg-slate-200 after:absolute after:left-[2px] after:top-[2px] after:h-5 after:w-5 after:rounded-full after:border after:border-slate-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-blue-600 peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-blue-300 dark:border-slate-600 dark:bg-slate-700 dark:peer-focus:ring-blue-800"
              ></div>
              {/* <span className="ml-3 text-sm font-medium text-slate-800 dark:text-slate-200"
                >Toggle theme</span
              > */}
            </label>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="inline-flex h-4 w-4"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z"></path></svg>


          </div>
          <button
            onClick={() => {
              handleSignOut();
            }}
            className="w-5/6 text-center items-center gap-x-2 rounded-lg bg-slate-700 px-3.5 py-2.5 text-center text-sm font-medium text-slate-50 hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-300"
          >
            Sign Out
          </button>
        </div>
      </div>
    </aside>
    </>
  );
};

export default Sidebar;
