import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import NewApp from "./NewApp";
import LandingPage from "./LandingPage";
import { ThemeProvider } from "./ThemeProvider";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

ReactDOM.createRoot(document.getElementById("root")).render(
	<React.StrictMode>
		<ThemeProvider>
			<Router>
				<Routes>
					{/* <Route path="/chat" element={<App />} /> */}

					<Route path="/chat" element={<NewApp />} />
					<Route path="/" element={<LandingPage />} />
				</Routes>
			</Router>
		</ThemeProvider>
	</React.StrictMode>
);

reportWebVitals();
