import React, { Component } from 'react';

function ChatInput({ inputValue, onInputChange, onKeyPress, handleInput, status }) {

// This function will be called when the form is submitted
const handleSubmit = (e) => {
  e.preventDefault(); // Prevents the default form submit action (page refresh)
  handleInput(inputValue); // Pass the current inputValue to the handleInput function
};


    return (
      <div className="w-full">
        {/* Rounded input */}
        <form onSubmit={handleSubmit}>
          <label htmlFor="chat-input" className="sr-only">Message YidGPT...</label>
          <div className="relative">
            {/* Voice input button (if applicable) */}
            <button
              type="button"
              className="absolute inset-y-0 left-0 flex items-center pl-3 text-slate-500 hover:text-blue-600 dark:text-slate-400 dark:hover:text-blue-600"
            >
              {/* Voice icon here */}
            </button>
            <textarea
              id="chat-input"
              className="mt-4 block w-full resize-none rounded-xl border-none bg-slate-200 p-4 pl-10 pr-20 text-sm text-slate-900 shadow-md focus:outline-none focus:ring-2 focus:ring-blue-600 dark:bg-slate-800 dark:text-slate-200 dark:placeholder-slate-400 dark:focus:ring-blue-600 sm:text-base"
              placeholder="Message YidGPT..."
              rows="1"
              required
              autoFocus
              value={inputValue}
              onChange={onInputChange}
              onKeyDown={onKeyPress}
            ></textarea>
            <button
              type="submit"
              // onClick={(e) => handleInput(e, true)}
              disabled={
                status === "loading" ||
                !inputValue.trim()
              }
              className={`text-xs	 absolute bottom-2 right-2.5 rounded-lg bg-blue-700 px-2 py-2 text-sm font-medium text-slate-200 hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 sm:text-base ${status === "loading" || !inputValue.trim() ? "opacity-50 cursor-not-allowed" : ""}`}
              >
                    <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6"
        aria-hidden="true"
        viewBox="0 0 24 24"
        strokeWidth="2"
        stroke="currentColor"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
        <path d="M10 14l11 -11"></path>
        <path
          d="M21 3l-6.5 18a.55 .55 0 0 1 -1 0l-3.5 -7l-7 -3.5a.55 .55 0 0 1 0 -1l18 -6.5"
        ></path>
      </svg>
       <span className="sr-only">Send message</span>
            </button>
          </div>
        </form>
      </div>
    );
}

export default ChatInput;