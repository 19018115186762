import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import "./LandingPage.css"; // Importing LandingPage CSS

function animateString(str, delayBase) {
	let array = str.split("").map((char, i) => (
		<span
			style={{ animationDelay: `${delayBase * i}s` }}
			className="animatedChar"
			dangerouslySetInnerHTML={{
				__html: char === " " ? "&nbsp;" : char,
			}}
		/>
	));
	array.push(
		<img
			style={{ animationDelay: `${delayBase * str.length + 0.5}s` }}
			className="animatedCharImg"
			src="/logo192.png"
		/>
	);
	return array;
}

function LandingPage() {
	const navigate = useNavigate();

	function handleSignUp() {
		navigate("/chat");
	}
	useEffect(() => {
		checkUser();
	}, []);

	async function checkUser() {
		try {
			await Auth.currentAuthenticatedUser();
			navigate("/chat");
		} catch (error) {
			// console.log("User not logged in");
		}
	}

	return (
		<div className="landing text-slate-800 dark:text-slate-200 dark:bg-slate-800">
			<h1>{animateString("Welcome to YidGPT", 0.1)}</h1>
			<p>
				A specially designed version of ChatGPT, providing safer content for our
				Jewish community.
			</p>
			<button id="signup" onClick={handleSignUp} 
			  class="inline-flex items-center rounded-lg px-3 py-2 text-center text-sm font-semibold text-blue-600 ring-1 ring-inset ring-blue-600 hover:bg-blue-600 hover:text-blue-50"
>
				Start Chatting
			</button>
		</div>
	);
}

export default LandingPage;
