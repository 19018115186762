import React, { useState, useEffect, useCallback } from 'react';
import { ThemeContext } from "./ThemeContext";

export function ThemeProvider({ children }) {
    // Will use local storage theme or system preference on initial render
    const getInitialTheme = useCallback(() => {
        const localTheme = localStorage.getItem('theme');
        if (localTheme) {
            return localTheme;
        } else {
            const prefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
            return prefersDark ? 'dark' : 'light';
        }
    }, []);

    const [theme, setTheme] = useState(getInitialTheme);

    // Toggling theme and saving the preference to local storage
    const toggleTheme = () => {
        const newTheme = theme === "light" ? "dark" : "light";
        setTheme(newTheme);
        localStorage.setItem('theme', newTheme);
    };

    // Set the class on the html element and update localStorage based on the current theme
    useEffect(() => {
        document.documentElement.className = theme;

        // Update localStorage if the theme was manually set by the user
        localStorage.setItem('theme', theme);
    }, [theme]);

    return (
        <ThemeContext.Provider value={{ theme, toggleTheme }}>
            {children}
        </ThemeContext.Provider>
    );
}